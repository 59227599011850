<template>
  <v-container fluid class="pa-4">
    <v-row>
      <v-col>
        <h1 class="text-h5">Eventos</h1>
      </v-col>
      <v-spacer></v-spacer>
      <div v-if="$store.state.events.eventSelected.id_place" class="pa-3">
        <NewEvent :currentPlace="currenIdPlace" @reloadEvents="GetEvents" />
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="6" v-if="$store.state.auth.user.admin === 1">
                <v-select
                  dense
                  v-model="currentIdCorp"
                  :loading="loading"
                  :items="$store.state.auth.corps"
                  outlined
                  append-icon="mdi-database-outline"
                  :item-text="({ name, idcorp }) => `${name} (Corp ${idcorp})`"
                  item-value="idcorp"
                  label="Corporación seleccionada"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  dense
                  v-model="currenIdPlace"
                  :loading="loading"
                  :hint="`${currenIdPlace.city || ''}, ${
                    currenIdPlace.state || ''
                  }`"
                  :items="eventPlaces"
                  outlined
                  append-icon="mdi-stadium"
                  item-text="eventplace"
                  item-value="id_place"
                  no-data-text="Aún no hay sitios configurados"
                  label="Sitio seleccionado"
                  persistent-hint
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-col >
                <v-text-field
                  v-model="search"
                  dense
                  outlined
                  append-icon="mdi-magnify"
                  label="Search..."
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
              :headers="headers"
              :items="events"
              :items-per-page="5"
              :search="search"
              :loading="loading"
              loading-text="Loading... Please wait"
              class="row-pointer"
              @click:row="OpenEvent"
            >
              <template v-slot:[`item.img`]="{ item }">
                <div style="padding: 10px" v-if="item.img">
                  <v-img :src="$root.pathevens + item.img" class="box"></v-img>
                </div>
                <div v-else>
                  <v-icon size="50px"> mdi-image-remove </v-icon>
                </div>
              </template>

              <template v-slot:[`item.id_place`]="{ item }">
                <div style="padding: 10px">
                  {{ getNamePlace(item.id_place) }}
                </div>
              </template>

              <template v-slot:[`item.event_config`]="{ item }">
                <div class="text-uppercase">
                  {{ GetEventsConfig(item.event_config) }}
                </div>
              </template>

              <template v-slot:[`item.category`]="{ item }">
                <div class="text-uppercase">
                  {{ GetEventCategory(item.category) }}
                </div>
              </template>

              <template v-slot:[`item.on_create`]="{ item }">
                {{ fixDate(item.on_create) }}
              </template>

              <template v-slot:[`item.activo`]="{ item }">
                <v-switch
                  v-model="item.activo"
                  inset
                  color="success"
                  @change="changeSwitch(item)"
                ></v-switch>
              </template>
            </v-data-table>
              </v-col>
            </v-row>
           
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EVENTMIXIN } from "@/mixins/eventsMixin";
import NewEvent from "../../components/events/NewEventComponent.vue";
export default {
  name: "Events",
  components: {
    NewEvent,
  },
  mixins: [EVENTMIXIN],
  data() {
    return {
      currentIdCorp: null,
      currenIdPlace: {
        id_place: null,
        eventplace: null,
        city: null,
        state: null,
      },
      eventPlaces: [],
      search: "",
      headers: [
        { text: "Activo", value: "activo" },
        {
          text: "Nombre del evento",
          align: "start",
          value: "name.ES",
        },
        { text: "Sitio del evento", value: "id_place" },
        { text: "Tipo de Evento", value: "event_config" },
        { text: "Categoria", value: "category" },
        { text: "Portada", value: "img", align: "center" },
        { text: "Creado", value: "on_create", align: "center" },
      ],
      events: [],
      loading: false,
      typeSitesx: [],
      switchActivate: false,
    };
  },
  watch: {
    currentIdCorp: function (val) {
      this.events = [];
      this.eventPlaces = [];
      this.$store.dispatch("auth/setSelectedCorp", val);
      this.chargeDefaultPlace();
    },
    currenIdPlace: function (val) {
      this.$store.dispatch("events/setEventSelected_A", {
        id_event: null,
        event_config: null,
        id_place: val.id_place,
      });
      if (val.id_place) this.GetEvents();
    },
  },
  mounted() {
    this.events = [];
    this.currentIdCorp = this.$store.state.auth.user.id_corp;
    if (this.$store.state.auth.user.admin !== 1) {
      this.chargeDefaultPlace();
    }
    this.$store.dispatch("events/resetBothSelections_A");
    this.typeSitesx = [...this.$store.state.catalogs.typeSites];
  },
  methods: {
    async chargeDefaultPlace() {
      await this.LoadPlace_Mixin();
      this.eventPlaces = [...this.$store.state.catalogs.allPlace];
      this.typeSitesx = [...this.$store.state.catalogs.typeSites];
      this.currentPlaceSelected = {
        id_place: null,
        eventplace: null,
        city: null,
        state: null,
      };
    },
    GetEvents() {
      this.loading = true;
      this.events = [];

      this.$root
        .post("/Admin/GetEvents", {
          id_place: this.$store.state.events.eventSelected.id_place,
        })
        .then((response) => {
          let info = response.data;

          if (info.success == "TRUE") {
            let eventsCharged = info.Data;
            this.events = eventsCharged;
          } else {
            this.events = [];
            this.$root.Toast("info", info.Msg);
          }
        })
        .catch((e) => {
          this.$root.swalAlert(
            "error",
            "Error al cargar los eventos",
            e.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    GetEventsConfig(event_config) {
      let configurations = { ...this.$store.state.catalogs.event_config_desc };
      return configurations[event_config] || "Sin configurar";
    },
    getNamePlace(id_placeX) {
      let res = this.eventPlaces.find(({ id_place }) => id_place === id_placeX);
      return res.eventplace || "Sin nombre";
    },
    GetEventCategory(id_category) {
      let res = this.typeSitesx.find((elem) => elem.id === id_category);
      return res?.name;
    },
    changeSwitch(item) {
      let preventValue = !item.activo
      this.switchActivate = true;

      if(!item.img) {
        setTimeout( ()  => {
          item.activo = false;
        }, 10)
        return this.$root.swalAlert("warning", "No se puede activar un evento sin imagen!");
      } 

      this.$swal({
        icon: "warning",
        title: "Configurar visibilidad del evento en su WebShop",
        text: "Esta acción hará que su evento se pueda o no mostrar en su WebShop",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Si",
        denyButtonText: `Don't save`,
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$root.post("/Admin/activateEventOnWebshop", 
            { 
              id_event: item.id_event,
              event_config: item.event_config,
              active: !preventValue
            }
          ).then((response) => {
            let info = response.data;
            if(info.success === "TRUE") {
              item.activo = !preventValue;
              if(!preventValue) this.$root.swalAlert("success", "El evento ahora ya se puede visualizar en su webshop");
              else this.$root.swalAlert("success", "El evento ya no sale en su webshop");
            } else {
              item.activo = preventValue;
              this.$root.swalAlert("warning", "Error", info.Msg);
            }
          })
          .catch(e => {
            item.activo = preventValue;
            this.$root.swalAlert("error", "Error", e.message);
          });
        } else {
          item.activo = preventValue
        }
      });
    },
    fixDate(date) {
      let x = date;
      if (date) {
        x = date.split("T");
        x = x[0];
      }
      return x;
    },
    OpenEvent(item, { index }) {
      const currentEvent = { ...item, index };

      if (!this.switchActivate) {
        this.$store.dispatch("events/setEventSelected_A", {
          id_event: item.id_event,
          event_config: item.event_config,
          id_place: item.id_place,
        });
        const MyEvent = { ...currentEvent };
        this.$store.dispatch("events/setInfoEventSelected_A", MyEvent);

        // this.compareGeneralInfo = JSON.stringify({...MyEvent})
        // this.vtab = 0;
        // this.dialogConfig = true;
        this.$router.push({
          name: "EventId",
          params: { id: MyEvent.id_event, MyEvent: MyEvent, currenIdPlace:this.currenIdPlace },
        });
      } else {
        this.switchActivate = false;
      }
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.row-pointer >>> tbody tr:nth-of-type(odd) {
  background: rgb(238, 238, 238, 0.5);
}
.box {
  border-radius: 5px;
  width: 120px;
  height: 70px;
  border: 0px solid #3f414a;
}
.box2 {
  position: relative;
  border-radius: 5px;
  width: 340px;
  height: 200px;
  background: #fff;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  border: 2px solid #3f414a;
  background-size: cover;
  background-position: 100% 100%;
}
.without {
  border: 0px solid #3f414a !important;
  box-shadow: 0 0 0px rgb(0 0 0 / 10%) !important;
}
</style>
