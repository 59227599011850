var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"text-h5"},[_vm._v("Eventos")])]),_c('v-spacer'),(_vm.$store.state.events.eventSelected.id_place)?_c('div',{staticClass:"pa-3"},[_c('NewEvent',{attrs:{"currentPlace":_vm.currenIdPlace},on:{"reloadEvents":_vm.GetEvents}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mt-3"},[(_vm.$store.state.auth.user.admin === 1)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dense":"","loading":_vm.loading,"items":_vm.$store.state.auth.corps,"outlined":"","append-icon":"mdi-database-outline","item-text":function (ref) {
                var name = ref.name;
                var idcorp = ref.idcorp;

                return (name + " (Corp " + idcorp + ")");
},"item-value":"idcorp","label":"Corporación seleccionada"},model:{value:(_vm.currentIdCorp),callback:function ($$v) {_vm.currentIdCorp=$$v},expression:"currentIdCorp"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dense":"","loading":_vm.loading,"hint":((_vm.currenIdPlace.city || '') + ", " + (_vm.currenIdPlace.state || '')),"items":_vm.eventPlaces,"outlined":"","append-icon":"mdi-stadium","item-text":"eventplace","item-value":"id_place","no-data-text":"Aún no hay sitios configurados","label":"Sitio seleccionado","persistent-hint":"","return-object":""},model:{value:(_vm.currenIdPlace),callback:function ($$v) {_vm.currenIdPlace=$$v},expression:"currenIdPlace"}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.events,"items-per-page":5,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.OpenEvent},scopedSlots:_vm._u([{key:"item.img",fn:function(ref){
                var item = ref.item;
return [(item.img)?_c('div',{staticStyle:{"padding":"10px"}},[_c('v-img',{staticClass:"box",attrs:{"src":_vm.$root.pathevens + item.img}})],1):_c('div',[_c('v-icon',{attrs:{"size":"50px"}},[_vm._v(" mdi-image-remove ")])],1)]}},{key:"item.id_place",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"padding":"10px"}},[_vm._v(" "+_vm._s(_vm.getNamePlace(item.id_place))+" ")])]}},{key:"item.event_config",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.GetEventsConfig(item.event_config))+" ")])]}},{key:"item.category",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.GetEventCategory(item.category))+" ")])]}},{key:"item.on_create",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixDate(item.on_create))+" ")]}},{key:"item.activo",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"success"},on:{"change":function($event){return _vm.changeSwitch(item)}},model:{value:(item.activo),callback:function ($$v) {_vm.$set(item, "activo", $$v)},expression:"item.activo"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }