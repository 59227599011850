<template>
  <v-dialog
    persistent
    no-click-animation
    v-model="dialogNewSitio"
    max-width="900"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark color="success" v-bind="attrs" v-on="on">
        Nuevo Evento
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card flat style="overflow: hidden">
      <v-toolbar class="px-2 text-h5 grey darken-4 white--text text-uppercase">
        CREAR NUEVO EVENTO
      </v-toolbar>

      <v-card-text class="py-5">
        <v-row>
          <v-col cols="12" md="6" style="min-height: 215px !important">
            <v-select
              v-model="event"
              :items="event_config"
              item-text="type"
              item-value="id"
              label="Tipo de evento"
              outlined
              style="padding-top: 10px !important"
            ></v-select>

            <v-select
              v-model="cata"
              :items="typeSite"
              item-text="name"
              item-value="id"
              label="Categoria del evento"
              outlined
              style="padding-top: 20px !important"
            ></v-select>

            <v-text-field
              v-model="currentPlace.eventplace"
              :hint="`${currentPlace.city || ''}, ${currentPlace.state || ''}`"
              label="Evento para el sitio"
              disabled
              persistent-hint
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="d-flex justify-center align-center">
            <v-img
              contain
              src="../../assets/free2.png"
              max-height="189"
              width="400"
              aspect-ratio="1"
              v-if="event === 3"
            />
            <v-img
              contain
              src="../../assets/time.png"
              max-width="260"
              aspect-ratio="1"
              v-if="event === 4"
            />
            <v-img
              contain
              src="../../assets/web.webp"
              max-width="200"
              aspect-ratio="1"
              v-if="event === 1"
            />
            <v-img
              contain
              src="../../assets/asientos.jpg"
              max-width="280"
              aspect-ratio="1"
              v-if="event === 5"
            />
          </v-col>
        </v-row>
        <v-form ref="nameForm">
          <v-row class="mb-5">
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                label="Nombre del evento ESPAÑOL"
                placeholder="Escribe un nombre de evento en ESPAÑOL"
                v-model="name_event['ES']"
                hint="Ejemplo: Parador Chichen Itza"
                persistent-hint
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                label="ENGLISH event name"
                placeholder="Write the name of the event in ENGLISH"
                v-model="name_event['EN']"
                hint="Example: Chichen Itza Parador"
                persistent-hint
                outlined
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12" class="pa-0 px-7 mb-2">
            <div class="t_Content">
              <div>
                <h2
                  class="overline font-weight-black pb-2"
                  style="font-size: 15px !important"
                >
                  DETALLES DEL EVENTO
                </h2>
              </div>
              <div
                v-for="(eventDes, index) in eventsDescription[event]"
                :key="index"
                class="itemE"
              >
                <strong>{{ eventDes }}</strong>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-spacer />
      <v-card-actions class="pa-0 px-7 pb-5">
        <v-btn
          color="error"
          @click="dialogNewSitio = false"
          :disabled="loadingBtn"
        >
          Cancelar
          <v-icon left dark>mdi-close-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="createNewEvent" :loading="loadingBtn">
          <v-icon left dark>mdi-check-circle</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EVENTMIXIN } from "@/mixins/eventsMixin";

export default {
  name: "NewEvent",
  props: ["currentPlace"],
  mixins: [EVENTMIXIN],
  data() {
    return {
      eventPlacesX: [],
      typeSite: [],
      event_config: [
        {
          id: 3,
          type: "Entrada Ilimitada (Regular)",
        },
        {
          id: 4,
          type: "Fechas con cupo limitado (Fechas)",
        },
        {
          id: 1,
          type: "Evento de redirección web",
        },
        {
          id: 5,
          type: "Evento con asientos",
        },
      ],
      name_event: {
        ES: null,
        EN: null,
      },
      cata: 3,
      event: 3,
      dialogNewSitio: false,
      eventsDescription: {
        3: [
          "* Venta ilimitada de boletos.",
          "* Acceso sin horario de restricción dentro de la hora laboral.",
        ],
        4: ["* Se accede en fecha y hora especifica", "* Tiene cupo limitado."],
        1: ["* Evento que va a redireccionar al usuario a otro sitio web."],
        5: [
          "* Evento que se accede en fecha y hora especifica.",
          "* Evento que cuenta con mapa asientos o espacios con cupo limitado.",
        ],
      },
      loadingBtn: false,
      rules: {
        nameRule: (v) => !!v || "the name is required!",
        nombreRule: (v) => !!v || "El nombre es requerido!",
      },
    };
  },
  watch: {
    dialogNewSitio: function (val) {
      if (!val) {
        this.event = 3;
        this.cata = 3;
        this.$refs.nameForm.reset();
        this.$refs.nameForm.resetValidation();
      }
    },
  },
  mounted() {
    this.eventPlacesX = [...this.$store.state.catalogs.allPlace];
    this.typeSite = this.gettypeSitesMix();
  },
  methods: {
    createNewEvent() {
      if (this.$refs.nameForm.validate()) {
        this.loadingBtn = true;

        this.$root
          .post("/Admin/createNewEvent", {
            name: this.name_event,
            category: this.cata,
            event_config: this.event,
            place: this.currentPlace,
          })
          .then((response) => {
            let info = response.data;
            if (info.success === "TRUE") {
              this.$emit("reloadEvents");
              this.dialogNewSitio = false;
              this.$root.swalAlert("success", "Evento creado");
            } else {
              this.$root.swalAlert(
                "error",
                "Error en la creación del evento",
                info.Msg
              );
            }
          })
          .catch((e) => {
            this.$root.swalAlert(
              "error",
              "Error en la creación del evento",
              e.message
            );
          })
          .finally(() => {
            this.loadingBtn = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.t_Content {
  min-height: 100px;
}
</style>
